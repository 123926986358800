.r-header {
  background-color: #000000;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  top: -82px;
  transition: top 0.3s ease-in-out;

  &__inner {
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
      width: calc(100% - 60px);
    }
  }
  &__logo-wrapper {
    padding: 10px 0;
    z-index: 90;

    a {
      @include fontSize(18);
      @include lineHeight(18, 18);
      color: #fff;
      font-weight: 700;
      text-decoration: none;
    }
    img {
      width: 170px;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        width: 150px;
      }
    }
  }
  &__menu-wrapper {
    display: flex;
    align-items: center;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
  &__mobile-menu-wrapper {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: flex !important;
      align-items: center;
      justify-content: center;

      label{
        display: flex;
        flex-direction: column;
        width: 35px;
        cursor: pointer;
      }

      label span{
        background: #ffffff;
        border-radius:10px;
        height: 3px;
        margin: 3px 0;
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }

      span:nth-of-type(1){
        width: 50%;
      }

      span:nth-of-type(2){
        width: 100%;
      }

      span:nth-of-type(3){
        width: 75%;
      }

      input[type="checkbox"]{
        display: none;
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(1){
        transform-origin: bottom;
        transform: rotatez(45deg) translate(4px,0px)
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(2){
        transform-origin: top;
        transform: rotatez(-45deg)
      }

      input[type="checkbox"]:checked ~ span:nth-of-type(3){
        transform-origin: bottom;
        width: 50%;
        transform: translate(15px, -4px) rotatez(45deg);
      }
    }
  }
  &__quick-items {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-bottom: 6px;
      margin-left: 100px;
    }

    .a {
      position: relative;
      cursor: pointer;

      .a-a {
        width: 24px;
        height: 18px;
        img {
          width: 100%;
        }
      }
      .a-b {
        position: absolute;
        top: -6px;
        left: 16px;
        color: #fff;
        width: 20px;
        height: 20px;
        background-color: $appRed100;
        border-radius: 50%;
        border-color: #fff;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 12px;
        line-height: 12px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__search-wrapper {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
}

//.scroll-down {
//  .r-header {
//    position: fixed;
//    top: -82px;
//    width: 100%;
//    z-index: 70;
//  }
//}
.scroll-up {
  .r-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 90;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      position: relative;
    }
  }
}
.scroll-up {
  main {
    padding-top: 82px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding-top: 0;
    }
  }
}
