.geosearch.active form {
  display: flex !important;
  flex-wrap: wrap;
}
.geosearch.active form input {
  width: 90% !important;
}
.geosearch.active form a {
  width: 10% !important;
  display: none;
}