.badge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 70;

  &__inner {
    position: relative;
    padding: 10px 18px;
    display: flex;
    align-items: center;

    @include fontSize(18);
    @include lineHeight(18, 18);
  }

  &__title {
    position: relative;
  }
  &__value {
    position: relative;
  }

  &--red {
    background-color: $appRed100;
    color: #ffffff;
    font-weight: 500;

    .badge__title {

    }

    .badge__value {

    }
  }

  &--out-of-stock {
    color: #7d7d7d;
    font-weight: 500;

    .badge__title {

    }

    .badge__value {

    }
  }

  &--right {
    right: 0;
    left: unset;
  }
}
