.hero-text-image {

  &__inner {
    padding: 100px 30px;
    display: flex;
    justify-content: space-between;
    gap: 60px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 30px 30px 90px 30px;
      flex-direction: column-reverse;
    }
  }

  &__inner--reverse {
    flex-direction: row-reverse;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 30px 30px 90px 30px;
      flex-direction: column-reverse;
    }
  }

  &__col {
    width: 100%;
    max-width: 50%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }

  &__col--l {
    padding-top: 60px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding-top: 0;
    }
  }

  &__title {
    padding: 0;
    margin: 0 0 15px 0;
    @include fontSize(40);
    @include lineHeight(40, 40);
    font-weight: 600;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }
  &__text {
    padding: 0;
    margin: 0;
    @include fontSize(16);
    @include lineHeight(24, 16);
  }
  &__image-wrapper {
   img {
     width: 100%;
   }
  }
  &__cta {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: content-box;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    font-weight: 500;

    &:hover {
      .hero-text-image__icon {
        margin-left: 10px;
      }
    }
  }
  &__icon {
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;

    svg {
      width: 24px;
      height: 24px;
      fill: #de8150;
    }
  }
}