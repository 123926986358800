.cart-nav {
  background-color: #2f2f2f;

  &__inner {
    display: flex;
    align-content: center;
    justify-content: center;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      justify-content: space-between;
    }
  }

  &__item {
    padding: 15px 100px;
    color: $appBlack100;
    background-color: #efefee;
    text-decoration: none;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 15px 30px;
      width: 33.33%;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  &__item--current {
    color: #ffffff;
    background-color: $appRed100;
    pointer-events: none;
  }
}
