.p-single-blog {
  &__inner {
    padding: 0 30px;
    display: flex;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__main {
    width: 100%;
    max-width: 70%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__aside {
    margin-top: 30px;
    width: 100%;
    max-width: 30%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__header {}
  &__header {}
  &__body {
    p {
      @include fontSize(18);
      @include lineHeight(28, 18);
      font-weight: 400;
    }
    ul {
      @include fontSize(18);
      @include lineHeight(28, 18);
    }

    a {
      color: $appRed100;
    }
  }
  &__footer {}
  &__tags-wrapper {
    display: flex;
    gap: 10px;
  }
  &__title {
    padding: 0;
    margin: 10px 0 10px 0;
    @include fontSize(44);
    @include lineHeight(44, 44);
    font-weight: 600;
  }
  &__subtitle {
    padding: 0;
    margin: 0 0 20px 0;
    @include fontSize(24);
    @include lineHeight(30, 24);
    font-weight: 400;
  }
  &__meta {
    margin-bottom: 10px;
    display: flex;
  }
  &__meta-clock {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.55);

    img {
      padding-right: 5px;
      width: 18px;
      height: 18px;
    }
  }
  &__meta-calendar {
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.55);

    img {
      padding-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
  &__image-wrapper {
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  &__related-products {
    display: flex;

    .product-card {
      width: 33.33%;
    }
  }
}