.mobile-main-menu {
  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  &__item {
    font-family: $fontRajdhani;
    @include fontSize(20);
    @include lineHeight(20, 20);
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__link-active {
    color: $appRed100 !important;
  }
}

.mobile-second-menu {
  margin-top: 30px;

  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  &__item {
    font-family: $fontRajdhani;
    @include fontSize(17);
    @include lineHeight(17, 17);
    font-weight: 400;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;
  }

  &__link-active {
    color: $appRed100 !important;
  }
}

.mobile-social-menu {
  margin-top: 40px;

  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  &__item {
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(16, 16);
    font-weight: 500;
    color: #fff;

    &:not(:last-child) {
      margin-right: 8px;
    }

    img {
      width: 21px;
      height: 21px;
    }
  }
  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.mobile-main-menu__submenu {
  display: none;
  z-index: 999;
  padding: 10px 0 0 10px;
  width: max-content;
}
.mobile-main-menu__submenu ul {
  padding: 0;
  margin-top: 0;
  list-style: none;
}
.mobile-main-menu__submenu ul li a {
  color: #ffffff;
  text-decoration: none;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 2em;
  font-weight: 600;
}
.mobile-main-menu__submenu ul li a:hover {
  background: -webkit-linear-gradient(-70deg, #712E30 0%, #4E3B40 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}
.mobile-main-menu__submenu--show {
  display: block;
}