.fact-banner {

  &--number {
    text-align: center;
  }

  &__inner {
    padding: 60px 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
     flex-direction: column;
    }
  }

  &__col {
    width: 100%;
    max-width: 33.3333%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }

  &__title {
    padding: 0;
    margin: 0 0 15px 0;
    @include fontSize(26);
    @include lineHeight(26, 26);
    font-weight: 600;
  }
  &__text {
    padding: 0;
    margin: 0;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-weight: 300;
  }
  &__number {
    padding: 0;
    margin: 0;
    @include fontSize(80);
    @include lineHeight(80, 80);
    font-weight: 600;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }
  &__cta {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: content-box;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    font-weight: 500;

    &:hover {
      .fact-banner__cta-icon {
        margin-left: 10px;
      }
    }
  }

  &__cta-icon {
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;

    svg {
      width: 24px;
      height: 24px;
      fill: #de8150;
    }
  }
}