.delivery {
  padding: 0 30px 45px 30px;

  &__inner {}
  &__header {}
  &__body {
    display: flex;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__footer {}
  &__main {
    max-width: 65%;
    width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__sidebar {
    padding: 15px 30px;
    max-width: 35%;
    width: 100%;
    background-color: #efefee;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: calc(100% - 60px);
    }
  }
  &__title {}
  &__next-button {
    padding: 12px 28px;
    display: block;
    border-radius: 3px;
    border: solid 2px #712E30;
    background-color: #712E30;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    width: fit-content;
  }
  &__products-list {
    padding: 0;
    list-style: none;
  }
  &__product-item {
    font-size: 1.1rem;
    line-height: 2rem;
    display: flex;
    justify-content: space-between;
  }
  &__product-link {
    font-weight: 500;
    text-decoration: none;
    color: #712E30;
  }
  &__product-quantity {
    color: initial;
    font-weight: initial;
  }
  &__product-price {
    text-align: right;
  }
  &__alert {
    margin-bottom: 15px;
    padding: 15px;
    background-color: #712E30;

    p {
      margin: 0;
      color: #ffffff;
    }

    a {
      color: #ffffff;
      font-weight: 600;
    }
  }

  .vb-button--go-to-checkout {
    width: 100% !important;
  }
}
