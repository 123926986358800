.p-contact {
  &__inner {
    padding: 0 30px;
  }

  &__map-wrapper {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  &__body {
    display: flex;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__meta-wrapper {
    width: 100%;
    max-width: 50%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }

    h2 {
      padding: 0;
      margin: 0;
    }
    p {
      padding: 0;
      margin: 0;
    }
    ul {
      padding: 0;
      margin: 15px 0 0 0;
      list-style: none;
      li {}
      a {
        background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;

        &:hover {
        }
      }
    }
  }
  &__form-wrapper {
    width: 100%;
    max-width: 50%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-top: 30px;
      max-width: 100%;
    }

    form {
      margin-top: 30px;
    }
  }
  &__form-title {
    padding: 0;
    margin: 0;
  }
  &__form-text {
    padding: 0;
    margin: 0;
  }
}