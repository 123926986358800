.main-menu {
  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  &__item {
    position: relative;
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(80, 16);
    font-weight: 600;
    letter-spacing: 0.2px;

    @include media("<=1068px") {
      @include lineHeight(16, 16);
    }

    @include media("<=992px") {
    }

    @include media("<=768px") {
    }

    @include media("<=576px") {
    }

    &:not(:last-child) {
      margin-right: 50px;

      @include media("<=1068px") {
        margin-right: 2rem;
      }

      @include media("<=992px") {
        margin-right: 1.6rem;
      }

      @include media("<=768px") {
        margin-right: 1rem;
      }

      @include media("<=576px") {
      }
    }
  }
  &__link {
    position: relative;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background: -webkit-linear-gradient(-70deg, $appRed100 0%, $appRed200 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
    }
  }

  &__link-active {
    background: -webkit-linear-gradient(-70deg, $appRed100 0%, $appRed200 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;

    &::before {
      //content: '';
      //position: absolute;
      //top: -23px;
      //left: 0;
      //width: 100%;
      //height: 4px;
      //background-color: $appRed100;
    }
  }

  &__submenu {
    display: none;
    position: absolute;
    z-index: 999;
    border-radius: 0;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    width: max-content;
    left: -15px;
    top: 64px;
    box-shadow: rgb(152 162 179 / 15%) 0 10px 20px 0, rgb(152 162 179 / 25%) 0 2px 6px 0;

    ul {
      padding: 0;
      margin-top: 0;
      list-style: none;

      li {
        a {
          color: #666666;
          text-decoration: none;
          font-family: $fontRajdhani;
          @include fontSize(16);
          @include lineHeight(32, 16);
          font-weight: 600;

          &:hover {
            background: -webkit-linear-gradient(-70deg, $appRed100 0%,  $appRed200 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;
          }
        }
      }
    }
  }

  &__submenu--show {
    display: block;
  }
}
