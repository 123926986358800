.cta-section {
  padding: 100px 0;
  text-align: center;
  background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: #0000003b;
    width: 100%;
    height: 100%;
  }

  &--style-1 {
    background-color: #ffffff;
  }

  &__inner {
    z-index: 3;
    position: relative;
  }
  &__title {
    @include fontSize(44);
    @include lineHeight(44, 44);
    font-weight: 600;
    letter-spacing: 0;
    color: #ffffff;
  }
  &__cta {
    position: relative;
    text-decoration: none;
    @include fontSize(18);
    @include lineHeight(18, 18);
    color: #ffffff;
    font-weight: 400;
    z-index: 50;

    &:hover {
      &::after {
        height: 4px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -10%;
      width: 120%;
      height: 2px;
      background-color: white;
      transition: height 0.3s ease-in-out;
    }
  }
}