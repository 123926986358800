.partners-section {
  &__inner {
    padding: 0 30px;
  }
  &__title {}
  &__items-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__item {}
  &__item-image {}
}