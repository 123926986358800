.lang-menu {
  position: relative;
  margin-left: 45px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    margin-top: 30px;
    margin-left: 0;
  }

  &__icon {
    height:16px;
    width: 16px;
    cursor: pointer;
  }
  &__items {
    position: absolute;
    margin: 0;
    display: none;
    list-style: none;
    z-index: 999;
    border-radius: 0;
    background-color: #ffffff;
    padding: 10px 20px 10px 20px;
    width: 14px;
    left: -15px;
    top: 34px;
    box-shadow: rgb(152 162 179 / 15%) 0 10px 20px 0, rgb(152 162 179 / 25%) 0 2px 6px 0;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: fit-content;
      left: 36px;
      top: -12px;
    }

  }
  &__items--open {
    display: block;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: flex;
    }
  }
  &__item {
    position: relative;
    font-family: $fontRajdhani;
    @include fontSize(14);
    @include lineHeight(24, 14);
    font-weight: 600;
    letter-spacing: 0;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  &__link {
    position: relative;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
  }
}