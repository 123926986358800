.vb-page {
  &--style-one {
    .vb-page__header,
    .vb-page__content {
      //margin-left: auto;
      //margin-right: auto;
      width: 100%;
      max-width: 689px;
    }
  }
  &--style-two {
    .vb-page__header,
    .vb-page__content {
      width: 100%;
      max-width: 100%;
    }
  }
  &__inner {
    padding: 0 30px;
  }
  &__header {}
  &__body {}
  &__body--have-aside {
    display: flex;
    gap: 60px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      gap: 0;
    }
  }
  &__footer {
    margin-top: 60px;
    img {
      position: relative;
      top: 5px;
      left: -168px;
      width: 100%;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        left: 0;
      }
    }
  }
  &__title {}
  &__subtitle {}
  &__content {
    h1 {
      @include fontSize(44);
      @include lineHeight(45, 44);
    }
    h2 {
      @include fontSize(30);
      @include lineHeight(36, 30);
    }
    h3 {
      margin-bottom: 0;
      @include fontSize(24);
      @include lineHeight(34, 24);
    }
    h4 {
      margin-bottom: 0;
      @include fontSize(20);
      @include lineHeight(26, 20);
    }
    h5 {}
    h6 {}
    p, ul, ol {
      @include fontSize(18);
      @include lineHeight(28, 18);
    }
    img {
      margin-bottom: 15px;
      width: 100%;
    }
  }
  &__aside {
    border-left: 1px solid #d5d5d5;
    padding: 60px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
}