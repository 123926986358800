.product {
  padding: 15px 0;
  //#283B4B

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin-bottom: 30px;
    padding: 30px 0;
  }

  &__header {}
  &__body {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-top: 0;
      padding: 0;
      flex-direction: column;
      gap: 0;
    }
  }
  &__footer {}

  &__meta-wrapper {
    width: 60%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-top: 30px;
      width: 100%;
    }
  }
  &__meta-header {
    display: flex;
    justify-content: space-between;
  }
  &__meta-header-l {}
  &__price-wrapper {
    margin: 15px 0;
    font-family: $fontRajdhani;
    @include fontSize(30);
    @include lineHeight(30, 30);
    font-weight: 700;
  }
  &__price-wrapper-kn {
    //display: flex;
    //align-items: center;

    //@include media("<=1400px") {}
    //
    //@include media("<=1200px") {}
    //
    //@include media("<=992px") {}
    //
    //@include media("<=768px") {}
    //
    //@include media("<=576px") {
    //  align-items: start;
    //  flex-direction: column;
    //}
  }
  &__price-wrapper-eu {
    display: flex;
    align-items: center;

    @include fontSize(26);
    @include lineHeight(26, 26);
  }
  &__price--have-discount-price {
    margin-right: 10px;
    @include fontSize(20);
    @include lineHeight(20, 20);
    .product__price-value {
      text-decoration: line-through;
    }
    .product__price-currency {
      text-decoration: line-through;
    }
  }
  &__discount-price {
    color: $appRed100;
    font-family: $fontRajdhani;
    @include fontSize(30);
    @include lineHeight(30, 30);
    font-weight: 700;
  }
  &__discount-price-value {}
  &__discount-price-currency {}
  &__price-value {}
  &__price-currency {}
  &__quantity {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      display: block;
      margin-bottom: 10px;
      @include fontSize(20);
      @include lineHeight(20, 20);
      font-weight: 500;
    }

    .number-input {
      input {
        width: 28px;
        height: 28px;
        border-radius: 0;
        border: 1px solid $appC4;

        @include fontSize(18);
        @include lineHeight(24, 18);
        font-weight: 500;
      }
      span {
        width: 32px;
        height: 32px;
        background-color: $appC4;
      }
    }
  }
  &__certificate-img {
    width: 70px;
  }
  &__meta-header-r {
    display: flex;
    flex-direction: column;

    .a11 {
      font-family: $fontRajdhani;
      @include fontSize(24);
      @include lineHeight(24, 24);
      font-weight: 700;
    }
    .b22 {}
  }

  &__meta-body {
  }
  &__meta-footer {
    //display: flex;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
    }
  }

  &__meta-footer--2 {
    display: flex;
    flex-direction: column-reverse;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
    }

    .product-packaging {
      margin-bottom: 15px;
      padding-bottom: 15px;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 40%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
      min-height: 385px;
    }
  }

  &__image {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
    height: auto;
    background-repeat: no-repeat;
    //background-size: cover;
    shape-margin: 0.75rem;
    background-position: center;
  }

  &__title {
    margin: 0;
    font-family: $fontRajdhani;
    @include fontSize(36);
    @include lineHeight(36, 36);
    font-weight: 700;
    text-transform: uppercase;
  }
  &__description {
    @include fontSize(18);
    @include lineHeight(24, 18);

    p {
      @include fontSize(18);
      @include lineHeight(24, 18);
    }

    ul {
      li {
        @include fontSize(18);
        @include lineHeight(24, 18);
      }
    }
  }
  &__tags {
    margin: 0;
    padding: 5px 0 0 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  &__tag-item {
    margin-bottom: 4px;
    padding: 4px 14px;
    background-color: $appRed100;
    color: black;
    border-radius: 3px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &__tag-link {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #000;
    }
  }
  &__back {
    display: block;
    margin-top: 30px;
    @include fontSize(12);
    @include lineHeight(12, 12);
    color: $appRed100;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  &__contact {
    padding-top: 10px;
    display: block;
    @include fontSize(14);
    @include lineHeight(14, 14);

    a {
      color: $appRed100;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.worko-tabs {
  margin-top: 30px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    display: none;
  }

  table {
    thead {
      background-color: #000000;
      color: #ffffff;

      tr {
        th {
          padding: 5px;
        }
      }
    }
  }
}

.mobile-accordion {
  margin-top: 15px;
  display: none;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    display: block;
  }

  &__button {
    padding: 10px;
    width: 100%;
    background-color: #293a4c;
    border-radius: 0;
    border: 0;
    color: #fff;
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__content {
    display: none;
  }

  &__content--show {
    z-index: 90;
    display: block;
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 30px;
    overflow-y: scroll;
  }

  &__content-close {
    text-align: right;
    margin-bottom: 30px;
    text-decoration: underline;
  }
  &__content-content {}
}

.product-packaging {
  label {
    display: block;
    margin-bottom: 10px;
    @include fontSize(20);
    @include lineHeight(20, 20);
    font-weight: 500;
  }
  &__item {
    margin-bottom: 10px;
    a {
      color: $appRed100;
      @include fontSize(20);
      @include lineHeight(20, 20);
      font-weight: 500;
    }
  }
}

.product-carousel {
  .swiper-slide {
    border-radius: 32px;
  }
  &__image {
    width: 100%;
    border-radius: 32px;
  }

  .swiper-button-prev, .swiper-button-next {
    background-color: transparent;
    border: 2px solid;
    border-color: #283b4c;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    top: unset;
    bottom: 15px;
  }

  .swiper-button-next {
    right: unset;
    left: 52px;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    color: #283b4c !important;
    font-size: 1rem !important;
  }
}

.pswp__img {
  height: auto !important;
}
