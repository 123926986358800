.s-checkout {
  margin-bottom: 30px;
  padding: 0 30px;

  &__header {
    padding: 30px;
    background-color: #deddd8;
  }
  &__body {

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {}
  }
  &__top {

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
    }

    .s-form-group__input {
      width: calc(100% - 30px);
    }
  }
  &__bottom {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
    }
  }
  &__footer {}
  &__form {
    display: flex;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__form-l {
    margin: 0 auto;
    padding-top: 30px;
    width: 60%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: calc(100%);
    }
  }
  &__form-r {
    width: 65%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: calc(100%);
    }

    .s-cart-table {
      padding: 30px 0 0 0 !important;
      width: 100% !important;
    }
  }

  &__title {
    margin: 0;
    @include fontSize(32);
    @include lineHeight(32, 32);
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(22);
      @include lineHeight(22, 22);
    }
  }
}
