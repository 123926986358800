.tags-cloud {
  &__inner {}
  &__header {}
  &__title {
    padding: 0;
    margin: 0 0 5px 0;
    @include fontSize(20);
    @include lineHeight(20, 20);
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  &__item {
    padding: 5px 15px;
    text-decoration: none;
    color: #ffffff;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    @include fontSize(14);
    @include lineHeight(14, 14);
  }
}