.tab {
  display: inline-block;
  padding: 18px 30px 13px 30px;
  vertical-align: top;
  background-color: $appC4;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  transition: all .2s linear;

  &:hover{
    background-color: $appC3;
    color: $appC4;
  }
}

#tab-1:checked ~ .tabs #tab-1-label,
#tab-2:checked ~ .tabs #tab-2-label,
#tab-3:checked ~ .tabs #tab-3-label,
#tab-4:checked ~ .tabs #tab-4-label,
#tab-5:checked ~ .tabs #tab-5-label,
#tab-6:checked ~ .tabs #tab-6-label,
#tab-7:checked ~ .tabs #tab-7-label,
#tab-8:checked ~ .tabs #tab-8-label {
  background-color: $appC3;
  color: $appC4;
  cursor: default;

  &:hover{
    color: unset;
  }
}

#tab-1:checked ~ .tabs #tab-1-panel,
#tab-2:checked ~ .tabs #tab-2-panel,
#tab-3:checked ~ .tabs #tab-3-panel,
#tab-4:checked ~ .tabs #tab-4-panel,
#tab-5:checked ~ .tabs #tab-5-panel,
#tab-6:checked ~ .tabs #tab-6-panel,
#tab-7:checked ~ .tabs #tab-7-panel,
#tab-8:checked ~ .tabs #tab-8panel{
  display: block;
}

@media (max-width: 600px){
  .flex-tabs{
    flex-direction: column;

    .tab{
      background: $appC3;
      border-bottom: 1px solid #ccc;

      &:last-of-type{
        border-bottom: none;
      }
    }

    #tab-1-label{order:1;}
    #tab-2-label{order: 3;}
    #tab-3-panel{order: 2;}
    #tab-4-panel{order: 4;}
    #tab-5-panel{order: 5;}
    #tab-6-panel{order: 6;}
    #tab-7-panel{order: 7;}
    #tab-8-panel{order: 8;}
  }

  #tab-1:checked ~ .tabs #tab-1-label,
  #tab-1:checked ~ .tabs #tab-2-label,
  #tab-3:checked ~ .tabs #tab-3-label,
  #tab-4:checked ~ .tabs #tab-4-label,
  #tab-5:checked ~ .tabs #tab-5-label,
  #tab-6:checked ~ .tabs #tab-6-label,
  #tab-7:checked ~ .tabs #tab-7-label,
  #tab-8:checked ~ .tabs #tab-8-label {
    border-bottom: none;
  }

  #tab-1:checked ~ .tabs #tab-1-panel,
  #tab-2:checked ~ .tabs #tab-2-panel,
  #tab-3:checked ~ .tabs #tab-3-panel,
  #tab-4:checked ~ .tabs #tab-4-panel,
  #tab-5:checked ~ .tabs #tab-5-panel ,
  #tab-6:checked ~ .tabs #tab-6-panel,
  #tab-7:checked ~ .tabs #tab-7-panel,
  #tab-8:checked ~ .tabs #tab-8-panel{
    border-bottom: 1px solid #ccc;
  }
}

.state{
  position: absolute;
  left: -10000px;
}

.flex-tabs{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .tab{
    max-height: 40px;
  }

  .panel {
    background-color: $appC3;
    padding: 20px;
    display: none;
    width: 100%;
    flex-basis: auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    // from 992px to 1180
    @include media(">992px", "<=1180px") {

    }

    // from 769px to 992px
    @include media(">769px", "<=992px") {

    }

    // from 481px to 768px
    @include media(">481px", "<=768px") {

    }

    // from 320px to 480px
    @include media(">=320px", "<=480px") {
      width: calc(100% - 30px);
      padding: 0 15px;
    }

    h3 {
      margin: 15px 0 0 0;
      padding: 0;
      @include fontSize(26);
      @include lineHeight(26, 26);
    }

    p {
      @include fontSize(18);
      @include lineHeight(26, 18);
      font-weight: 400;
      color: #000000;
    }
  }
}