// Reset
@import "~normalize.css";
@import "vanilla-cookieconsent/dist/cookieconsent.css";

// Helpers
@import "~include-media/dist/include-media";
@import "helpers/mixins";
@import "helpers/variables";
@import "helpers/global";

// layout
@import "layout/header";
@import "layout/footer";

// pages
@import "pages/page.page";
@import "pages/contact.page";
@import "pages/blog.page";
@import "pages/single-blog.page";
@import "pages/about-us.page";
@import "pages/gallery.page";
@import "pages/delivery.page";

// components
@import "components/container.component";
@import "components/back-to-top.component";
@import "components/main-menu.component";
@import "components/mobile-aside.component";
@import "components/mobile-main-menu.component";
@import "components/fullwidth-section.component";
@import "components/promo-section.component";
@import "components/cta-section.component";
@import "components/partners-section.component";
@import "components/articles-section.component";
@import "components/product-article.component";
@import "components/top-bar.component";
@import "components/form-group.component";
@import "components/button.component";
@import "components/post-card.component";
@import "components/tag-chip.component";
@import "components/hero-text-image.component";
@import "components/fact-banner.component";
@import "components/tags-cloud.component";
@import "components/text-banner.component";
@import "components/gallery-section.componets";
@import "components/product-card.component";
@import "components/quick-cart.component";
@import "components/main-slider.component";
@import "components/accordion.component";
@import "components/page-menu.component";
@import "components/cart-nav.component";
@import "components/cart.component";
@import "components/s-cart-table.component";
@import "components/flash-notice.component";
@import "components/checkout.component";
@import "components/images-gallery.component";
@import "components/latest-posts.component";
@import "components/product.component";
@import "components/breadcrumbs.component";
@import "components/feeding-instruction-section.component";
@import "components/ingredients-section.component";
@import "components/meals-size-number.component";
@import "components/tabs.component";
@import "components/ingredient-item.component";
@import "components/badge.component";
@import "components/payment-type.component";
@import "components/diet-benefits.component";
@import "components/benefit-item.component";
@import "components/product-quality.component";
@import "components/feature-products.component";
@import "components/team-member.component";
@import "components/lang-menu.component";
@import "components/product-variation-item.component";
@import "components/ch-cookie-consent.component";
