.s-button {
  cursor: pointer;
  transition: all .2s linear;
  text-align: center;
  text-decoration: none;

  &:hover {
    border: solid 0 #0d79cb;
    background-color: #0d79cb;
  }

  &:disabled {
    opacity: .5;
  }

  &--block {
    display: block;
  }
  &--blue {
    padding: 17px 134px 16px 133px;
    border-radius: 5px;
    border-width: 0;
    background-color: #0091ff;
    color: #ffffff;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 17px 0 16px 0;
      width: 100%;
    }
  }
  &--cookie {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    border: solid 2px #0091ff;
    background-color: #0091ff;
    color: #ffffff;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 17px 0 16px 0;
      width: 100%;
    }
  }
  &--cart-1 {
    padding: 10px 30px;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: $appRed100;
    color: #ffffff;
  }
  &--cart-2 {
    padding: 10px 30px;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: #ffffff;
    color: $appRed100;

    &:hover {
      color: #ffffff;
    }
  }
  &--mr-15 {
    margin-right: 15px;
  }
  &--remove {
    padding: 4px 20px;
    border-radius: 2px;
    border: solid 1px $appRed100;
    background-color: #ffffff;
    color: $appRed100;
    @include fontSize(13);
    @include lineHeight(13, 13);

    &:hover {
      color: #ffffff;
      border: solid 0 #0d79cb;
    }
  }

  &--submit {
    text-align: center;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 17px 30px;
    border-radius: 3px;
    border: solid 0 #0a0a0a;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    color: #ffffff;
    font-weight: 600;
  }

  &--go-to-checkout {
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 15px 30px;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: $appRed100;
    color: #ffffff;
  }

  &--font-600 { font-weight: 600; }
  &--font-700 { font-weight: 700; }

  &--hidden {
    display: none;
  }

  &--hide-mobile {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
}

.vb-button {
  cursor: pointer;
  transition: all .2s linear;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  border-width: 0;

  &:hover {
    border: solid 0 #0d79cb;
    background-color: #0d79cb;
  }

  &:disabled {
    opacity: .5;
  }

  &--block {
    display: block;
  }

  &--add-to-cart {
    padding: 20px 55px;
    background-color: $appRed100;
    color: #fff;
    font-weight: 600;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 90;
      @include fontSize(22);
    }

    &:hover {
      background-color: #4E3B40;
    }
  }

  &--product-contact {
    padding: 20px 55px;
    display: block;
    width: fit-content;
    background-color: $appRed100;
    color: #fff;
    font-weight: 600;

    &:hover {
      background-color: #4E3B40;
    }
  }

  &--cart-1 {
    padding: 10px 30px;
    border-radius: 3px;
    border-width: 0;
    background-color: $appRed100;
    color: #ffffff;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-bottom: 5px;
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: $appC4;
    }
  }

  &--cart-2 {
    padding: 8px 28px;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: #ffffff;
    color: $appRed100;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: block;
      width: 100%;
    }

    &:hover {
      color: #ffffff;
      background-color: $appC4;
      border: solid 2px $appC4;
    }
  }

  &--go-to-products {
    padding: 12px 28px;
    display: block;
    width: fit-content;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: #ffffff;
    color: $appRed100;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: calc(100% - 60px);
    }

    &:hover {
      color: #ffffff;
      background-color: $appC4;
      border: solid 2px $appC4;
    }
  }

  &--go-to-checkout {
    margin-top: 30px;
    padding: 14px 45px;
    border-radius: 3px;
    border-width: 0;
    background-color: $appRed100;
    color: #ffffff;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-top: 15px;
      display: block;
      width: calc(100% - 90px);
      font-weight: 600;
    }

    &:hover {
      background-color: $appC4;
    }
  }

  &--submit {
    margin-top: 15px;
    padding: 16px 60px;
    border-radius: 3px;
    border-width: 0;
    background-color: $appRed100;
    color: #ffffff;
    @include fontSize(18);
    @include lineHeight(18, 18);
    font-weight: 600;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-bottom: 30px;
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: $appC4;
    }
  }

  &--hide-mobile {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }

  &--cookie {
    padding: 10px 20px;
    border-radius: 3px;
    border-width: 0;
    background-color: $appRed100;
    color: #ffffff;
    @include fontSize(16);
    @include lineHeight(16, 16);
    font-weight: 500;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: block;
      width: 100%;
    }

    &:hover {
      background-color: $appC4;
    }
  }

  &--width-100 {
    width: 100%;
  }
}
