.back-to-top {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  background-color: #000000;
  border-radius: 50%;
  z-index: 90;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;

  @include media("<=576px") {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  &--show {
    visibility: visible;
    opacity: 1;
  }

}
