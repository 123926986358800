.fullwidth-section {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__inner {
    position: relative;
    padding: 160px 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 60px 30px;
    }
  }

  &__title {
    margin: 0 0 5px 0;
    padding: 0;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    @include fontSize(70);
    @include lineHeight(70, 70);
    font-weight: 600;
    max-width: 50%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
      @include fontSize(50);
      @include lineHeight(50, 50);
    }
  }
  &__title--center {
    text-align: center;
  }
  &__subtitle {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-weight: 400;
    @include fontSize(22);
    @include lineHeight(30, 22);
    max-width: 60%;
    text-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    letter-spacing: 0px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__subtitle--center {
    text-align: center;
  }
}