.main-slider {
  //height: 460px;

  &__inner {
  }
  &__slide {
    position: relative;
    width: 100%;
    height: 460px;
    background-color: black;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {}

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.50);
      z-index: 1;
    }

    img {
      width: 100%;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {}
    }
  }

  &__slide-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__slide-container {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    max-width: 1180px;
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    top: 84px;
    min-height: 460px;
    z-index: 2;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 0 30px;
      top: 30px;
      max-width: calc(100% - 60px);
    }
  }
  &__slide-title, &__slide-ubtitle {
    margin: 0;
    padding: 0;
    font-family: $fontRajdhani;
  }
  &__slide-title {
    color: #fff;
    @include fontSize(40);
    @include lineHeight(40, 40);
    font-weight: 700;
    text-shadow: 1px 1px 15px #0000007a;
    max-width: 30%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: unset;
    }
  }
  &__slide-subtitle {
    color: #fff;
    @include fontSize(20);
    @include lineHeight(26, 20);
    font-weight: 400;
    max-width: 50%;
    text-shadow: 1px 1px 15px #0000009e;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: unset;
    }
  }
  &__slide-cta {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all .2s linear;
    text-align: center;
    text-decoration: none;
    padding: 15px 30px;
    border-radius: 0;
    border-width: 0;
    background: rgb(113, 46, 48);
    color: #ffffff;

    &:hover {
      border-width: 0;
      background: rgba(113, 46, 48, 0.8);
    }

    &::before {
      position: relative;
      top: 1px;
      color: inherit;
      content: "↗";
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 3px;
      line-height: 0;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        display: none;
      }
    }
  }
  &__slide-image {
    display: unset !important;
    position: absolute;
    right: 30px;
    bottom: 84px;
    width: unset !important;
    height: 97%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none !important;
    }
  }
}
