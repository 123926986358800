.page-menu {
  padding: 0;
  margin-top: 0;
  list-style: none;

  &__item {
    position: relative;
    @include fontSize(18);
    @include lineHeight(34, 18);
    font-weight: 500;
    color: #666666;

    &::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 22px;
      top: 4px;
      left: -10px;
      background-color: #666666;
    }
  }
  &__link {
    color: #666666;
    text-decoration: none;
  }

  &__link-active {
    background: -webkit-linear-gradient(-70deg, #71be9c 0%,  #044e5b 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
  }
}