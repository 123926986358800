.accordion {
  background-color: #eee;
  color: $appBlack75;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  @include fontSize(16);
  @include lineHeight(20, 16);
  transition: 0.4s;
  font-weight: 600;
}

.active, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;

  p {
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-weight: 400;
    color: $appBlack75;
  }
}