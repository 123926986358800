.s-cart-table {
  padding: 30px;
  width: 100%;

  &--cart {
    padding: 15px 0;
    width: 100%;
    margin: 0 auto;
  }

  &--products {
    width: calc(100% - 60px);
    padding-bottom: 0;
  }
  &--checkout {
    padding-top: 0;
  }

  &--success-order {
    padding: 0;
  }

  &__title {
    margin-bottom: 15px;
    @include fontSize(22);
    @include lineHeight(22, 22);
    font-weight: 600;
  }

  th {
    padding: 15px 0;
    text-align: left;
    color: #000;
    box-shadow: 0px -1px 0px #ECEEEF;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      padding: 10px 0;
    }
  }

  &__tr-title {
    @include fontSize(22);
    @include lineHeight(22, 22);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(16);
      @include lineHeight(18, 16);
    }
  }
  &__tr-quantity {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }
  &__tr-single-price {

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      display: none;
    }
  }
  &__tr-total-price {}
  &__tr-remove {
    width: 100px;
  }
  &__tr-title--left {
    text-align: left !important;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
    }
  }
  &__tr-title--left {
    text-align: left !important;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
      max-width: 30%;
    }
  }
  &__tr-title--right {
    text-align: right !important;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
    }
  }

  //
  &__header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
  }
  &__header-product-title-col {
    width: 160px;
  }
  &__header-quantity-col {}
  &__header-total-col {}

  &__item {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    border-bottom: 1px solid #deddd7;


    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(16);
      @include lineHeight(18, 16);
    }
  }
  &__item--last {
    border-bottom: 0 solid #deddd7;
  }
  &__item-product-title-col {
    width: 70%;
    //display: flex;
    //align-items: center;
  }
  &__item-quantity-col {
    width: 10%;
    //display: flex;
    //align-items: center;
  }
  &__item-total-col {
    width: 20%;
    text-align: right;
    font-weight: 600;
  }

}
