.team-member {
  margin-bottom: 45px;
  padding-bottom: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #d5d5d5;
  }

  &__header {
    display: flex;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__image-wrapper {
    width: 40%;
    max-width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
    }
  }
  &__image {
    width: 100%;
  }
  &__meta {
    padding-top: 0px;
    padding-left: 30px;
    width: 60%;
    max-width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding-left: 0;
      width: 100%;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
  }
  &__subtitle {
    margin: 0;
    padding: 0;
  }
}