.ch-cookie-consent {
  width: calc(100% - 60px);
  padding: 30px;

  &::before {
    content: "";
    position: absolute;
    top: -100vh;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
  }
  &__title {
    margin-bottom: 0;
  }
  &__read-more {}
  &__btn-group {
    margin-bottom: unset !important;
  }
  &__btn-wrapper {
    margin-bottom: unset !important;
  }
  &__form {
    margin-top: 30px;
  }
  &__category-group {
    margin-top: 30px;
    border-top: 1px solid #ddd;
  }
  &__custom-wrapper {
    display: flex;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      flex-direction: column;
    }

    @include media("<=576px") {}
  }
  &__category {
    flex-direction: column;
    border: unset !important;
    width: 23%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      padding: 5px 0;
      width: 100%;
    }

    @include media("<=576px") {}
  }
  &__category-information {
    margin-top: 15px;
  }
  &__category-title {
    font-size: 1.2rem;
  }
  &__category-description {
    margin-top: 5px;
  }
  &__toggle-details-show {
    text-decoration: underline;
    font-weight: 500;
  }
  &__toggle-details-hide {
    text-decoration: underline;
    font-weight: 500;
  }
  &__category-toggle input + label {
    background-repeat: no-repeat;
    background-position: 45px center;
    background-color: #fff;
    color: rgb(113, 46, 48);
    border: 1px solid rgb(113, 46, 48);
    transition: all 0.2s;
    display: inline-block;
    margin-right: 4px;
    padding: 6px 10px 6px 10px;
    cursor: pointer;
    border-radius: 6px;
    width: unset;
  }
  &__category-toggle input:checked + label, &__category-toggle input + label:hover {
    background-color: rgb(113, 46, 48);
    color: #fff;
    //border-color: unset;
    border: 1px solid rgb(113, 46, 48) !important;
  }
  &__btn {
    cursor: pointer;
  }
  &__btn-group .ch-cookie-consent__toggle-details {
    margin-bottom: 0;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      margin-top: 30px;
    }

    @include media("<=576px") {}
  }
}

.ch-cookie-consent--simplified .ch-cookie-consent__category-toggle {
  display: unset !important;
}

#cookie_consent_use_only_functional_cookies {
  background: unset;
  background-color: rgb(113, 46, 48);
  border-color: rgb(113, 46, 48);
  box-shadow: unset !important;
  outline:0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {
    margin-bottom: 10px;
  }

  @include media("<=576px") {}
}
