.s-flash-notice {
  margin-bottom: 30px;
  position: relative;
  z-index: 90;

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    margin-bottom: 0;
  }

  &__inner {
    margin: 0 auto 0 auto;
    padding: 15px 15px;
    max-width: 1090px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $appRed100;
    color: #fff;
    @include fontSize(14);
    @include lineHeight(16, 14);
    font-style: normal;
    font-weight: 500;

    @include media("≥1400px") {}

    @include media("≥1200px") {}

    @include media("≥992px") {}

    @include media("≥768px") {}

    @include media("≥576px") {}

    @include media("<576px") {
      margin: 0 -30px 0 -30px;
      position: relative;
      top: -30px;
      border-radius: 0;
    }
  }

  a {
    color: #ffffff;
    text-decoration: none;
    transition: color 300ms ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }

  &--absolute {
    position: absolute;
    left: 0;
    top: 68px;
    width: 100%;
    z-index: 9;

    .s-flash-notice__inner {
      max-width: calc(940px - 30px);
      padding: 15px;
    }
  }
}
