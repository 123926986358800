.s-footer {
  background-color: #0a0a0a;
  color: #ffffff;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {}

  &__inner {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }

  &__col {
    width: 50%;
    max-width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: 100%;
    }
  }
  &__col-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__col-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__copy {
    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin: 30px 0 15px 0;
    }
  }
  &__author {
    a {
      background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
    }
  }
}

.footer-menu {

  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__item {
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(16, 16);
    font-weight: 400;
    color: #fff;

    &:not(:last-child) {
      margin-right: 15px;
      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        margin-bottom: 15px;
      }
    }
  }
  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-social-menu {
  margin-right: 15px;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    margin-bottom: 15px;
  }

  &__items {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  &__item {
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(16, 16);
    font-weight: 500;
    color: #fff;

    &:not(:last-child) {
      margin-right: 8px;
    }

    img {
      width: 21px;
      height: 21px;
    }
  }
  &__link {
    position: relative;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
}
