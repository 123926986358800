.promo-section {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__inner {
    position: relative;
    padding: 120px 30px;
    display: flex;
    flex-direction: column;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 90px 30px;
    }
  }

  &__title {
    margin: 0 0 10px 0;
    padding: 0;
    background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    @include fontSize(50);
    @include lineHeight(50, 50);
    font-weight: 600;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__content {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-weight: 400;
    @include fontSize(20);
    @include lineHeight(30, 20);
    max-width: 70%;
    text-shadow: 0 1px 3px rgb(0 0 0 / 15%);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__cta {
    background: rgb(113, 46, 48);
    color: #fff;
    border: none;
    padding: 15px 30px;
    @include fontSize(17);
    @include lineHeight(17, 17);
    position: relative;
    opacity: 1;
    z-index: 1;
    display: inline-block;
    text-align: center;
    width: fit-content;
    margin-top: 15px;
    text-decoration: none;

    &:hover {
      border-width: 0;
      background: rgba(113, 46, 48, 0.8);
    }

    &::before {
      position: relative;
      top: 1px;
      color: inherit;
      content: "↗";
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 3px;
      line-height: 0;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        display: none;
      }
    }
  }
}
