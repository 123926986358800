.product-variation-item {
  display: flex;

  &__image-wrapper {
    width: 20%;
    padding: 15px;
  }
  &__image {

  }
  &__meta-wrapper {
    width: 80%;
  }
  &__title {}
}
