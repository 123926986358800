// Colors
$appRed100: #712E30;
$appRed75: rgba(113, 46, 48, 0.75);
$appRed50: rgba(113, 46, 48, 0.50);
$appRed25: rgba(113, 46, 48, 0.25);

$appRed200: #4E3B40;

$appBlack100: #000000;
$appBlack75: rgba(0, 0, 0, 0.75);
$appBlack50: rgba(0, 0, 0, 0.50);
$appBlack25: rgba(0, 0, 0, 0.25);

// Fonts
$fontRajdhani: 'Rajdhani', sans-serif;


$appC1: #712E30;
$appC2: #4A4E53;
$appC3: #E2E1D9;
$appC4: #283B4B;

// Import Font
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');