.p-blog {
  margin-bottom: 60px;
  &__inner {
    padding: 0 30px;
  }
  &__body {}
  &__posts-wrapper {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}