.diet-benefits {
  &__inner {
    padding: 30px 30px 60px 30px;
  }

  &__title {
    margin-bottom: 5px;
    @include fontSize(42);
    @include lineHeight(42, 42);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin-top: 0;
    }
  }

  &__subtitle {
    margin: 0 0 60px 0;
    @include fontSize(28);
    @include lineHeight(34, 28);
    font-weight: 400;
    width: 80%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      margin: 0 0 30px 0;
      @include fontSize(24);
      @include lineHeight(28, 24);
      width: 100%;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      gap: 0;
    }
  }
  &__image-wrapper {
    width: 100%;
    max-width: 55%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
  &__items-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 45%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
}