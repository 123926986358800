.latest-posts {
  padding: 60px 0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    padding: 0 0 30px 0;
  }

  &__inner {
    padding: 0 30px;
  }

  &__body {
    margin-top: 15px;
    display: flex;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      gap: 0;
    }
  }

  &__title {
    margin: 0 0 5px 0;
    @include fontSize(32);
    @include lineHeight(32, 32);
  }
  &__subtitle {
    margin: 0;
    max-width: 70%;
    @include fontSize(22);
    @include lineHeight(28, 22);
    font-weight: 400;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }

    a {
      position: relative;
      text-decoration: none;
      color: $appRed100;
      font-weight: 500;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1.4px;
        background-color: $appRed100;
        transition: width 0.2s ease-in-out;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
}