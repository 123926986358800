.feature-products {
  margin: 30px 0 120px 0;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    margin: 30px 0 60px 0;
  }

  &__inner {
    padding: 0 30px;
  }
  &__body {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-wrap: wrap;

      .product-card {
        &:not(:last-child) {
          margin-bottom: 45px;
        }
      }
    }

    .swiper {
      .product-card {
        width: 100%;
      }
    }
  }
  &__title {
    margin: 0 0 5px 0;
    @include fontSize(32);
    @include lineHeight(32, 32);
  }
  &__subtitle {
    margin: 0;
    max-width: 70%;
    @include fontSize(22);
    @include lineHeight(28, 22);
    font-weight: 400;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }

    a {
      position: relative;
      text-decoration: none;
      color: $appRed100;
      font-weight: 500;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {}

      @include media("<=768px") {}

      @include media("<=576px") {
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1.4px;
        background-color: $appRed100;
        transition: width 0.2s ease-in-out;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
  &__footer {
    position: relative;
    padding: 30px 0;

    .swiper-pagination {
      position: relative;
      text-align: left;

      .swiper-pagination-bullet {
        width: 38px;
        height: 4px;
        border-radius: 2px;
      }

      .swiper-pagination-bullet-active {
        background: rgb(113, 46, 48);
      }
    }
  }
}
