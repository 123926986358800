.benefit-item {
  display: flex;
  gap: 15px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &__icon-wrapper {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $appC4;
    border-radius: 50%;
  }
  &__icon {
    height: 32px;
    width: 32px;
    fill: #bf9f8a;
  }
  &__meta {
    width: 80%;
  }
  &__title {
    margin: 0;
    padding: 0;
    @include fontSize(32);
    @include lineHeight(32, 32);
    font-weight: 400;
  }
  &__description {
    margin: 0;
    padding: 0;
    @include fontSize(18);
    @include lineHeight(22, 18);
  }
}