.product-quality {
  &__inner {
    padding: 0 30px;
    display: flex;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 45%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }

    p {
      @include fontSize(22);
      @include lineHeight(28, 22);
    }

    img {
      width: 100%;
    }
  }
  &__image-wrapper {
    width: 100%;
    max-width: 55%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }

    img {
      position: relative;
      top: 5px;
      width: 100%;
    }
  }
}