.r-mobile-aside {
  padding: 30px;
  display: none;
  width: calc(100vw - 60px);
  height: calc(100vh - 88px);
  background-color: #000;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    display: block;
    position: absolute;
    top: 70px;
    z-index: 9000;
  }

  &--show {
    transform: translateX(0) !important;
  }
}
