.images-gallery {
  margin-bottom: 15px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 31.88%;
  }
}