.card {
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 0.5rem;
  width: 31.5%;
  transition: 250ms all ease-in-out;
  cursor: pointer;

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 40px #d7dfe2;
  }

  &__banner-img {
    position: absolute;
    object-fit: cover;
    height: 14rem;
    width: 100%;
  }

  &__card-body {
    margin: 15rem 1rem 1rem 1rem;
  }

  &__hashtag {
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    color: $appC2;
  }

  &__title {
    margin-top: 10px;
    margin-bottom: 10px;
    @include fontSize(26);
    @include lineHeight(26, 26);
  }

  &__description {
    @include fontSize(18);
    @include lineHeight(24, 18);
    color: #616b74;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 0;
  }
}


.product-card-wrapper {
  &__breadcrumbs {
    padding: 0 30px;
  }
  &__inner {
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
    }
  }
  &__inner--related-products {
    margin-top: 30px;
    margin-bottom: 60px;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      flex-direction: column;
      padding: 0;
    }
  }
}
