.gallery-section {
  &__inner {
    padding: 0 30px 60px 30px;
  }
  &__images-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  &__item {
    width: 100%;
    max-width: 23%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 47%;
    }

    img {
      width: 100%;
    }
  }
}