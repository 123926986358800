.product-card {
  position: relative;
  overflow: hidden;
  background: white;
  border-radius: 0;
  width: 22.991%;
  transition: 250ms all ease-in-out;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {}

  @include media("<=768px") {}

  @include media("<=576px") {
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 40px #d7dfe2;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }

  .product-card-swiper {
    padding-bottom: 14px;
  }

  &__banner-img {
    object-fit: cover;
    height: 100%;
    width: 100%;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {}
  }

  &__card-banner {
    .swiper-pagination {
      bottom: -5px !important;
      left: 10px !important;
      right: unset;
      text-align: left;

      .swiper-pagination-bullet {
        width: 34px !important;
        border-radius: 0;
        height: 3px;
      }

      .swiper-pagination-bullet-active {
        background: #712E30;
      }
    }
  }

  &__card-body {
    padding:  0 15px 15px 15px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 5px 15px 15px 15px;
    }
  }

  &__hashtag {
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    color: $appC2;
  }

  &__title {
    margin-top: 10px;
    margin-bottom: 10px;
    @include fontSize(26);
    @include lineHeight(26, 26);
  }

  &__description {
    @include fontSize(18);
    @include lineHeight(24, 18);
    color: #616b74;
    min-height: 66px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      min-height: 80px;
    }
  }

  &__meta {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    min-height: 105px;
  }

  &__meta-b {
    margin-bottom: 10px;
    font-size: .8rem;
  }

  &__price-wrapper {
    width: 100%;
  }
  &__price-wrapper--have-special-price {
    .product-card__price {
      @include fontSize(18);
      @include lineHeight(18, 18);
      text-decoration: line-through;
    }
    .product-card__price-special {
      @include fontSize(22);
      @include lineHeight(22, 22);
      font-weight: 600;
    }
  }
  &__price {
    @include fontSize(22);
    @include lineHeight(22, 22);
    font-weight: 600;
  }
  &__price-special {}

  &__price-eu-wrapper {
    .product-card__price {
      @include fontSize(18);
      @include lineHeight(18, 18);
      font-weight: 600;
    }
    .product-card__price-special {
      @include fontSize(20);
      @include lineHeight(20, 20);
      font-weight: 600;
    }
  }

  &__add-to-cart {
    position: relative;
    font-weight: 600;
    text-decoration: none;
    z-index: 20;
    color: $appRed100;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1.6px;
      background-color: $appRed100;
      transition: .2s all ease-in-out;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 0;
  }
}
