.product-article {
  position: relative;

  &--3 {
    max-width: 30%;
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__image {
    width: 100%;
    margin-bottom: 30px;
  }
  &__title {
    padding: 0;
    margin: 0;
    @include fontSize(22);
    @include lineHeight(22, 22);
  }
  &__description {
    padding: 0;
    margin: 0;
  }
  &__link {

  }
}