body, html{
  overflow-x: hidden;
}

body {
  font-family: $fontRajdhani;
  overflow: hidden;
  overflow-y: auto;
}

p, ul {
  margin-top: 5px;
  @include fontSize(16);
  @include lineHeight(24, 16);
  color: $appBlack75;
}

::-moz-selection {
  color: #ffffff;
  background: #a29061;;
}

::selection {
  color: #ffffff;
  background: #a29061;;
}

.no-padding {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
}

img {
  vertical-align: middle;
}

.swiper {
  height: 100%;
}

.swiper-slide {
  height: auto !important;
}
.swiper-content {
  height: 100% !important
}
