.top-bar {
  background: -webkit-linear-gradient(-70deg, #c51e22 0%, #e7a863 100%);
  color: #ffffff;

  &__inner {
    padding: 5px 30px;
    display: flex;
    align-items: center;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      justify-content: space-between;
    }
  }

  &__phone {
    margin-left: 15px;
    @include fontSize(15);
    @include lineHeight(15, 15);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(14);
      @include lineHeight(14, 14);
    }
  }
  &__email {
    @include fontSize(15);
    @include lineHeight(15, 15);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      @include fontSize(14);
      @include lineHeight(14, 14);
    }
  }
  &__icon {
    margin-left: auto;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      display: none;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
}