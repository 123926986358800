.quick-cart {
  position: relative;

  &__drop-down {
    padding: 25px;
    position: absolute;
    top: 24px;
    right: -42px;
    width: 314px;
    background-color: #fff;
    box-shadow: rgb(152 162 179 / 15%) 0 10px 20px 0, rgb(152 162 179 / 25%) 0 2px 6px 0;
    border-radius: 0;
    display: none;
    z-index: 900;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      width: calc(100vw - 50px);
      top: 38px;
      right: -94px;
      border-radius: 0 0 5px 5px;
    }
  }
  &__drop-down-footer {}
  &__button-cart {
    padding: 12px 28px;
    margin-top: 15px;
    display: block;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: #ffffff;
    color: $appRed100;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: #ffffff;
      background-color: $appC4;
      border: solid 2px $appC4;
    }
  }
  &__button-checkout {
    margin-top: 10px;
    padding: 12px 28px;
    display: block;
    border-radius: 3px;
    border: solid 2px $appRed100;
    background-color: $appRed100;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      color: #ffffff;
      background-color: $appC4;
      border: solid 2px $appC4;
    }
  }

  &--open {
    .quick-cart__drop-down {
      display: block;
    }
  }
}

.quick-cart-item {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: solid 1px #c2c2c2;

  &--last {
    border-bottom: solid 0 #c2c2c2;
  }

  &__header {}
  &__body {}
  &__footer {
    margin-top: 15px;
  }

  &__title {
    font-family: $fontRajdhani;
    @include fontSize(16);
    @include lineHeight(16, 16);
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
  }
  &__quantity {

  }
  &__price-wrapper {}
  &__price-wrapper-kn {
    display: flex;
  }
  &__price-wrapper-eu {
    display: flex;
  }
  &__price-value {}
  &__price-currency {}
}

.qc {
  display: flex;
  justify-content: space-between;

  &__l {}
  &__r {}
}