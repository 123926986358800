.ingredient-item {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(41, 58, 76, 0.20);

  &--last {
    margin-bottom: 0;
    border-bottom: 0 solid rgba(41, 58, 76, 0.20);
  }

  &__icon-wrapper {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    img {
      width: 68px;
    }
  }
  &__title {
    margin: 0 0 0 0;
    @include fontSize(30);
    @include lineHeight(30, 30);
    font-weight: 700;
  }
  &__description {
    p {
      @include fontSize(18);
      @include lineHeight(26, 18);
    }
  }
}