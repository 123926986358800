.mc-breadcrumbs {

  @include media("≥1400px") {}

  @include media("≥1200px") {}

  @include media("≥992px") {}

  @include media("≥768px") {}

  @include media("≥576px") {}

  @include media("<576px") {
    display: none;
  }

  &__inner {
    margin: 0 auto;
    padding: 15px 0 0 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  &__item {
    position: relative;
    color: black;
    @include fontSize(12);
    @include lineHeight(18, 12);
    font-style: normal;
    font-weight: normal;

    &:not(:last-child) {
      margin-right: 20px;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        top: 7px;
        right: -11px;
        background-color: black;
        border-radius: 50%;
      }
    }


  }
  &__item--current {
    color: $appRed100;
    font-style: normal;
    font-weight: bold;
  }
  &__link {
    color: black;
    text-decoration: none;
    transition: all .2s linear;

    &:hover {
      color: $appRed100;
    }
  }
}
