.s-form-group {
  margin-bottom: 20px;

  &__label {
    @include fontSize(16);
    @include lineHeight(24, 16);
    font-style: normal;
    font-weight: bold;
    color: $appBlack100;
  }
  &__label--light {
    font-weight: 400;
  }
  &__checkbox-wrapper {
    cursor: pointer;
  }
  &__label--small {
    @include fontSize(14);
    @include lineHeight(20, 14);
  }
  &__link {
    color: $appBlack100;
    font-weight: 400;
  }
  &__link--small {
    @include fontSize(14);
    @include lineHeight(20, 14);
  }
  &__input,
  &__textarea {
    padding: 12px 15px;
    width: calc(100% - 30px);
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  &__input--invalid {
    border: 1px solid $appRed100;
  }

  &__select {
    padding: 12px 15px;
    width: calc(100%);
    border: 1px solid #F2F2F2;
    border-radius: 4px;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  &__link {
    color: $appRed100;
  }

  &__input--checkout {
    padding: unset;
    width: unset;
    border: unset;
    border-radius: unset
  }

  &--checkout {
    .ms-form-group__label {
      @include fontSize(14);
      @include lineHeight(24, 14);
      font-style: normal;
      font-weight: normal;
      color: $appBlack100;

      a {
        color: #D9534F;
      }
    }
  }

  &--radio {
    margin-bottom: 10px;

    input {
      margin-right: 5px;
    }
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $appRed100;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
  }

  .c {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;
  }

  /* Hide the browser's default checkbox */
  .c input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .c:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .c input:checked ~ .checkmark {
    background-color: #000000;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .c input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .c .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  a {
    color: #000000;
  }

  &__input--hide {
    display: none;
  }
  &__input--hide {
    display: none;
  }
}
.coupon-code-input {
  padding: 9px 15px;
  border: 1px solid #929292;
  border-radius: 4px;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}
