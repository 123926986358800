.text-banner {
  &__inner {
    padding: 100px 30px;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 30px;
    }
  }
  &__primary {
    margin: 0 0 5px 0;
    @include fontSize(32);
    @include lineHeight(32, 32);

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
  &__secondary {
    margin: 0;
    max-width: 70%;
    @include fontSize(22);
    @include lineHeight(28, 22);
    font-weight: 400;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {}

    @include media("<=576px") {
      max-width: 100%;
    }
  }
}